//
// Footer
//

footer {
  // background-color: hsla(0, 0, 0, 0.045);
  a {
    color: theme-color('primary');
    &:hover,
    &:focus {
      color: theme-color('special');
      text-decoration: none;
    }
  }

  // space links to pass lighthouse audit
  li {
    padding-bottom: 5px;
  }
}
