/* SVG Stuff */

.svg-container {
  width: 30%;
  padding-top: 5%;
  margin-bottom: -10%;
  fill: theme-color('primary');
}

.st0 {
  fill: none;
  stroke: theme-color('primary');
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
