/* The hero image */

.hero-image {
  /* The image used */
  /* background-image: url('/img/landing/hero-1920x960.jpg'); */

  /* Set a specific height */
  min-height: 40vh;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero {
  width: 100%;
  // padding: 60px 30px;

  /* Set a specific min height */
  min-height: 80vh;

  /* Position and center the image to scale nicely on all screens */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .color-wash {
    /* Set a specific min height */
    min-height: 80vh;
    /* color overlay */
    background-color: rgba(0, 0, 0, 0.5);
  }
}
