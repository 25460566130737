.outer {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
  padding: 0;
}

.canvas-container {
  height: 75%;
}
